import React from "react";

import Aux from "../../../../hoc/Aux";

import appStoreBadgeImage from "../../../../assets/images/AppStoreBadge@2x.png";
import googlePlayStoreBadgeImage from "../../../../assets/images/GooglePlayBadge@2x.png";
import webBadgeImage from "../../../../assets/images/WebBadge@2x.png";

const StoreButtons = (props) => {
  const showOnlyAppStoreButtons =
    props.homePage.show_app_store_buttons &&
    !props.homePage.show_sign_in_button;
  const showOnlySignInButton =
    !props.homePage.show_app_store_buttons &&
    props.homePage.show_sign_in_button;
  const columnClasses = showOnlySignInButton
    ? "col-lg-12 col-md-12 col-sm-12 col-xs-12"
    : showOnlyAppStoreButtons
    ? "col-lg-6 col-md-6 col-sm-12 col-xs-12"
    : "col-lg-4 col-md-4 col-sm-12 col-xs-12";

  return (
    <Aux>
      <div className="row">
        {props.homePage.show_app_store_buttons && (
          <>
            <div className={columnClasses}>
              <a
                href={props.homePage.organization.app.app_store_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appStoreBadgeImage}
                  style={{ width: "150px", height: "52px", margin: "10px 0" }}
                />
              </a>
            </div>
            <div className={columnClasses}>
              <a
                href={props.homePage.organization.app.google_play_store_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googlePlayStoreBadgeImage}
                  style={{ width: "165px", height: "52px", margin: "10px 0" }}
                />
              </a>
            </div>
          </>
        )}

        {props.homePage.show_sign_in_button && (
          <div className={columnClasses}>
            <a
              href={`${props.homePage.organization.web_platform_link}/join`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={webBadgeImage}
                style={{ width: "150px", height: "52px", margin: "10px auto" }}
              />
            </a>
          </div>
        )}
      </div>
    </Aux>
  );
};

export default StoreButtons;
