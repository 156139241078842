import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Pages from '../components/Pages/Pages';
import NotFound from '../components/Error/NotFound';
import { ROUTES } from '../routes/Routes.config';
import RedirectToApps from '../components/Apps/RedirectToApps';

class Layout extends Component {

  render() {
    let routes = (
      <Switch>
        <Route exact path={ROUTES.NOT_FOUND} component={NotFound} />
        <Route path={ROUTES.GET_APP} exact component={RedirectToApps} />
        <Route path={ROUTES.ORGANIZATION_LEVEL_PAGES} exact component={Pages} />
        <Route path={ROUTES.PROGRAM_LEVEL_PAGES} exact component={Pages} />
        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>
    );
    return routes;
  }
};

export default Layout;
