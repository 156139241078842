import React from 'react';
import { envService } from '../../services';
import qooperLogo from '../../assets/images/qooper.png';

const PoweredBy = (props) => (
  <div className="navbar navbar-default navbar-fixed-bottom" style={{display: 'flex', alignItems: 'center', height: '60px'}}>
      <div style={{fontSize: '20px', margin: 'auto'}}>
        <a href="https://qooper.io" target="_blank" rel="noopener noreferrer">
          <img src={qooperLogo} style={{width: '40px', height: '40px', borderRadius: '4px'}}/>
        </a>
          &nbsp;&nbsp;

        <a href="https://qooper.io" target="_blank" rel="noopener noreferrer" style={{color: '#2baa92', margin: '0px auto', display: 'inline-block'}}>Powered by Qooper</a>
      </div>
  </div>
);

export default PoweredBy;
