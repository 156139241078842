import React from "react";
import Aux from "../../../hoc/Aux";

const PromoContent = (props) => {
  const styles = {
    width: "120px",
    height: "120px",
    borderRadius: "4px",
    border: "1px solid #F7F7F7",
  };
  return (
    <Aux>
      <img src={props.homePage.home_page_logo_url} style={styles} />
      <h1 style={{ margin: "10px 0px 0px" }}>
        {props.homePage.home_page_promo_title}
      </h1>
      {props.homePage.home_page_promo_text && (
        <p style={{ fontSize: "1.2em" }}>
          {props.homePage.home_page_promo_text}
        </p>
      )}
    </Aux>
  );
};

export default PromoContent;
