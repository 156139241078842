import axios from 'axios';
import envService from './env';

const apiService = {
  getApp: (appId, success, error) => {
    axios.get(`${envService.serverUrl()}/v1/promo/apps/${appId}/download`)
      .then(res => {
        success(res.data);
      })
      .catch(err => {
        error(err);
      });
  },
  getHomePage: (organizationId, programId, success, error) => {
    const requestUrl = `${envService.serverUrl()}/v1/promo/promo_websites/home_page?organization_id=${organizationId}${!!programId ? `&program_id=${programId}` : ''}`;
    
    axios.get(requestUrl)
      .then(res => {
        success(res.data);
      })
      .catch(err => {
        error(err);
      });
  },
  sendSMS: (appId, phoneNumber, success, error) => {
    axios.post(`${envService.serverUrl()}/v1/promo/apps/${appId}/sms`, {'phone_number': phoneNumber})
      .then(res => {
        success(res);
      })
      .catch(err => {
        error(err);
      });
  }
}

export default apiService;
