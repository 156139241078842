import React from 'react';

import organizationPlaceholderLogo from '../../../assets/images/organization-placeholder.png';

const organizationImage = (props) => {
  let classes = []
  let styles = null
  if (props.rounded) {
    classes.push('img-rounded');
  }
  if (props.large) {
    classes.push('img-lg');
  }
  if (props.small) {
    classes.push('img-sm');
  }
  return <img
        src={props.src || organizationPlaceholderLogo}
        alt={props.alt}
        className={classes.join(' ')}
        style={{borderRadius: '2px', border: '1px solid #F7F7F7', marginRight: '5px'}} />
};

export default organizationImage;
