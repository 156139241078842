import React from 'react';

import Spinner from '../Spinner';

export const Button = (props) => {
  let classes = ['btn'];
  let icon = null;

  if (props.extraClasses && props.extraClasses.length !== 0) {
    classes = [...classes.concat(props.extraClasses)];
  };

  return (
    <button
      style={props.style}
      type={props.type}
      onClick={props.action}
      className={classes.join(' ')}
      disabled={props.disabled}>
      {props.loading ? <Spinner dark={!props.emptyBg} inline sm/> : null}
      {props.loading ? null : props.children}
    </button>
  )
};
