import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

class IntPhoneInput extends Component {

  componentDidMount() {
    this.iti = intlTelInput(this.el, {
      initialCountry: 'us',
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.6/js/utils.js'
    });
  };

  componentDidUpdate() {
    if (this.props.phone === '') {
      this.el.value = ''
    }
  }

  componentWillUnmount() {
    this.iti.destroy();
  }

  render () {
    return <input
          ref={el => this.el = el}
          className="form-control"
          type="tel"
          onChange={() => this.props.changeHandler(this.iti)} />
  };
};

export default IntPhoneInput;
