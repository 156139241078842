import React from 'react';
import QRCode from 'qrcode.react';

import Modal from '../../UI/Modal';

const QRCodeModal = (props) => {
  return (
    <Modal
      show={props.show}
      close={props.close}>
      <QRCode
        style={{margin: '10px auto', display: 'block'}}
        size={250}
        value={props.homePage.organization.app.getapp_link} />
      <p style={{textAlign: 'center', fontSize: '2em'}}>Scan the QR code with your camera to download the app</p>
    </Modal>
  )
}

export default QRCodeModal;
