const securityService = {
  encodeId: (id) => {
    return encodeURIComponent(btoa(parseInt(id, 10)));
  },
  decodeId: (id) => {
    const parsedId = parseInt(id, 10);
    if (Number.isInteger(parsedId)) {
      return null;
    } else {
      try {
        return atob(decodeURIComponent(id));
      } catch (e) {
        return null;
      }
    }
  }
}

export default securityService;
