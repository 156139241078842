import qooperLogo from '../assets/images/qooper.png'

const envService = {
  getEnvironment: () => {
    switch (window.location.origin) {
      case 'https://promo.qooper.io':
        return 'production';
      case 'https://staging.promo.qooper.io':
      case 'http://localhost:3000':
        return 'staging';
      default:
        return 'development';
    }
  },
  serverUrl: () => {
    switch (envService.getEnvironment()) {
      case 'production':
        return 'https://api.qooper.io';
      case 'staging':
        return 'https://staging.api.qooper.io';
      default:
        return 'http://localhost:3000';
    }
  },
  getAppCreds: {
    'https://promo.qooper.io': {
      appId: 2,
      appName: 'Qooper',
      appLogo: qooperLogo,
      appColor: '#2BAA92',
      promoPageUrl: 'https://promo.qooper.io',
      serverUrl: 'https://api.qooper.io',
      appStoreUrl: 'https://itunes.apple.com/us/app/qooper/id1339883352?ls=1&mt=8',
      googlePlayStoreUrl: 'https://play.google.com/store/apps/details?id=com.cognatatechnologies.cooper'
    },
    'https://staging.promo.qooper.io': {
      appId: 1,
      appName: 'Qooper',
      appLogo: qooperLogo,
      appColor: '#2BAA92',
      promoPageUrl: 'https://staging.promo.qooper.io',
      serverUrl: 'https://staging.api.qooper.io',
      appStoreUrl: 'https://itunes.apple.com/us/app/qooper/id1339883352?ls=1&mt=8',
      googlePlayStoreUrl: 'https://play.google.com/store/apps/details?id=com.cognatatechnologies.cooper'
    },
    'http://localhost:3000': {
      appId: 1,
      appName: 'Qooper',
      appLogo: qooperLogo,
      appColor: '#2BAA92',
      promoPageUrl: 'https://staging.promo.qooper.io',
      serverUrl: 'https://staging.api.qooper.io',
      appStoreUrl: 'https://itunes.apple.com/us/app/qooper/id1339883352?ls=1&mt=8',
      googlePlayStoreUrl: 'https://play.google.com/store/apps/details?id=com.cognatatechnologies.cooper'
    }
  }
};

export default envService;
