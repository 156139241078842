import React from 'react';

const spinner = (props) => {
  let styles = null;

  if (props.dark) {
    styles = {
      ...styles,
      borderTop: '1.1em solid rgba(255, 255, 255, 0.2)',
      borderRight: '1.1em solid rgba(255, 255, 255, 0.2)',
      borderBottom: '1.1em solid rgba(255, 255, 255, 0.2)',
      borderLeft: '1.1em solid #fff'
    }
  }
  if (props.sm) {
    styles = {
      ...styles,
      fontSize: '2px'
    }
  }
  if (props.md) {
    styles = {
      ...styles,
      fontSize: '5px'
    }
  }
  if (props.inline) {
    styles = {
      ...styles,
      display: 'inline-block',
      verticalAlign: 'middle'
    }
  }
  if (props.styles) {
    styles = {...props.styles}
  }
  return (
    <div className="loader" style={styles}>Loading...</div>
  )
};

export default spinner;
