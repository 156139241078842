import React from 'react';

import Aux from '../../hoc/Aux'
import PageContainer from '../../containers/PageContainer';
import MainContent from '../../containers/MainContent/MainContent';
import Content from '../../containers/MainContent/Content';

const NotFound = (props) => (
  <PageContainer>
    <MainContent>
      <Content>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 style={{
                  fontSize: '180px',
                  color: '#4a4a4a'
                }}>404</h1>
              <h2 style={{
                  fontSize: '80px',
                  color: '#4a4a4a'
                }}>Page Not Found</h2>
            </div>
          </div>
        </div>
      </Content>
    </MainContent>
  </PageContainer>
)

export default NotFound;
