import React from "react";

import Aux from "../../../../hoc/Aux";

const DesktopDownloadOptions = ({
  homePage,
  dark,
  openQRModalHandler,
  openTMTAModalHandler,
}) => {
  const showTextMeTheAppButton = homePage.show_text_me_app_button;
  const showScanQRCodeButton = homePage.show_scan_app_qr_code_button;

  const desktopDownloadOptions = [
    showScanQRCodeButton
      ? {
          icon: "icon-transform",
          title: "Scan QR Code",
          action: openQRModalHandler,
        }
      : null,
    showTextMeTheAppButton
      ? {
          icon: "icon-smartphone",
          title: "Text Me The App",
          action: openTMTAModalHandler,
        }
      : null,
  ].filter(Boolean);

  const showDesktopDownloadButtons = desktopDownloadOptions.length !== 0;
  if (!showDesktopDownloadButtons) {
    return null;
  }

  const columnClasses =
    desktopDownloadOptions.length === 1
      ? "col-lg-12 col-md-12 col-sm-12 col-xs-12"
      : "col-lg-6 col-md-6 col-sm-12 col-xs-12";

  return (
    <Aux>
      <hr />
      <div className="row vcenter">
        {desktopDownloadOptions.map((option, index) => {
          return (
            <div
              className={columnClasses}
              key={`app-download-desktop-options-${index}`}
            >
              <a
                href="javascript:void(0)"
                onClick={(event) => option.action(event)}
                style={{
                  display: "flex",
                  alignItems: "end",
                  fontWeight: "bold",
                  color: dark ? "#4A4A4A" : "white",
                }}
              >
                <i className={option.icon} style={{ fontSize: "25px" }}></i>
                <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                  {option.title}
                </span>
              </a>
            </div>
          );
        })}
      </div>
    </Aux>
  );
};

export default DesktopDownloadOptions;
