import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from './Form';

const modal = (props) => {
  let dialogClasses = ['modal-dialog'];
  if (props.full) {
    dialogClasses.push('modal-full');
  }
  if (props.large) {
    dialogClasses.push('modal-lg');
  }
  return (
    <Modal show={props.show}
            onHide={() => { props.close(); props.exiting && props.exiting() }}
            dialogClassName={dialogClasses.join(' ')}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        {props.btnAction &&
          <Button
            loading={props.btnLoading}
            disabled={props.btnDisabled}
            action={props.btnAction}
            extraClasses={[props.btnKind || 'btn-primary']}>
            {props.btnTitle}
          </Button>
        }
        {props.secondaryBtnTitle &&
          <Button action={props.close}>{props.secondaryBtnTitle}</Button>
        }
      </Modal.Footer>
    </Modal>
  )
};

export default modal;
