import React, { Component } from "react";

import Aux from "../../../hoc/Aux";
import PromoContent from "./PromoContent";
import StoreButtons from "./Elements/StoreButtons";
import DesktopDownloadOptions from "./Elements/DesktopDownloadOptions";
import QRCodeModal from "./QRCodeModal";
import TextMeTheAppModal from "./TextMeTheAppModal";

class JoinContent extends Component {
  state = {
    showQRModal: false,
    showTMTAModal: false,
  };

  openQRModalHandler = (e) => {
    e.preventDefault();
    this.setState({ showQRModal: true });
  };

  closeQRModalHandler = () => {
    this.setState({ showQRModal: false });
  };

  openTMTAModalHandler = (e) => {
    e.preventDefault();
    this.setState({
      showTMTAModal: true,
      isSMSSent: false,
      isTMTAFormSubmitted: false,
    });
  };

  closeTMTAModalHandler = () => {
    this.setState({ showTMTAModal: false });
  };

  render() {
    const { homePage, isHomePage, dark } = this.props;

    const columnSpace = (
      <div className="col-lg-3 col-md-3 hidden-sm hidden-xs vcenter"></div>
    );
    const doubleColumnSpace = (
      <Aux>
        {columnSpace}
        {columnSpace}
      </Aux>
    );
    return (
      <Aux>
        {/* Content */}
        <div className="container" style={{ height: "100%" }}>
          <div
            className="row"
            style={{
              color: dark ? "#4A4A4A" : "white",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            {homePage.home_page_content_alignment === "right" &&
              isHomePage &&
              doubleColumnSpace}
            {(homePage.home_page_content_alignment === "center" ||
              !isHomePage) &&
              columnSpace}
            <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12 vcenter"
              style={{ textAlign: "center" }}
            >
              <PromoContent homePage={homePage} />
              {(homePage.show_app_store_buttons ||
                homePage.show_sign_in_button) && (
                <StoreButtons homePage={homePage} />
              )}
              <DesktopDownloadOptions
                {...{
                  dark,
                  homePage,
                  openQRModalHandler: this.openQRModalHandler,
                  openTMTAModalHandler: this.openTMTAModalHandler,
                }}
              />
            </div>
            {homePage.home_page_content_alignment === "left" &&
              isHomePage &&
              doubleColumnSpace}
            {(homePage.home_page_content_alignment === "center" ||
              !isHomePage) &&
              columnSpace}
          </div>
        </div>
        {this.props.footer}
        {/* Modals */}
        {/* QR Code Modal */}
        {this.state.showQRModal && (
          <QRCodeModal
            show={this.state.showQRModal}
            close={this.closeQRModalHandler}
            homePage={homePage}
          />
        )}
        {/* Text Me The App Modal */}
        {this.state.showTMTAModal && (
          <TextMeTheAppModal
            show={this.state.showTMTAModal}
            close={this.closeTMTAModalHandler}
            homePage={homePage}
          />
        )}
      </Aux>
    );
  }
}

export default JoinContent;
