import React from 'react';

import qooperLogo from '../../assets/images/qooper.png';

import OrganizationImage from '../Organizations/Organization/Image';

// import { DownloadAppButton } from '../UI/Buttons';

const OrganizationNavItem = (props) => (
  <li>
    <OrganizationImage
      large
        src={props.homePage.logo_url || qooperLogo}
        alt="Organization Logo" />
  </li>
)

const NavItem = (props) => {
  let titleStyle = {
    fontSize: '16px'
  }
  if (props.item.is_active) {
    titleStyle = {
      ...titleStyle,
      ...{
        borderBottom: `3px solid ${props.color}`,
      }
    }
  }
  return (
      <li>
        <a href="javascript:void(0);" onClick={(event) => props.clickHandler(event, props.item)} style={{padding: '21px'}}>
          <span
            style={titleStyle}>
            {props.item.navigation_title}
          </span>
        </a>
      </li>
  )
}

const NavigationToolbar = (props) => {
  return (
    <div id="navbar" className="navbar navbar-default navbar-component navbar-fixed-top" style={{borderRadius: '0px'}}>
      <div className="navbar-collapse collapse" id="navbar-filter" style={{height: '60px'}}>
        <ul className="nav navbar-nav" style={{display: 'flex', alignItems: 'center'}}>
          <OrganizationNavItem homePage={props.homePage} />
          {props.navigationItems.map(item => {
            return (
              <NavItem
                key={`page-${item.id}`}
                item={item}
                color={props.homePage.organization.color}
                clickHandler={props.navigationItemClickHandler} />
            )
          })}
        </ul>

        <div className="navbar-right">
          <ul className="nav navbar-nav" style={{display: 'flex', alignItems: 'center'}}>
            <li>{props.rightButton}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default NavigationToolbar;
