import React, { Component } from 'react';
import { apiService, envService, securityService } from '../../services';
import UAParser from 'ua-parser-js';

class RedirectToApps extends Component {
  state = {
    platform: null
  }

  componentDidMount() {
    const appId = securityService.decodeId(this.props.match.params.encoded_app_id);

    if (!appId) { this.props.history.push('/'); }

    const appCreds = envService.getAppCreds[window.location.origin];
    const defaultApp = {
      id: 0,
      app_store_url: appCreds.appStoreUrl,
      google_play_store_url: appCreds.googlePlayStoreUrl
    };
    if (appId) {
      apiService.getApp(appId, (res) => {
        this.redirectToAppStore(res.data);
      }, (err) => {
        this.redirectToAppStore(defaultApp);
      });
    } else {
      this.redirectToAppStore(defaultApp);
    }
  }

  redirectToAppStore = (app) => {
    const uap = new UAParser(window.navigator.userAgent);
    const result = uap.getResult();
    // const browser = result.browser;
    const device = result.device;
    if (device.vendor) {
      window.location = device.vendor === 'Apple' ? app.app_store_url : app.google_play_store_url;
      // this.setState({ platform: device.vendor });
    } else {
      const env = envService.envs[window.location.origin];
      window.location = `${env.promoPageUrl}${app.id === 0 ? '' : `/apps/${app.id}`}`
      // this.setState({ platform: browser.name });
    }
  }

  render() {
    return (
      <div style={{padding: '50px'}}>
        <div style={{width: '100%'}}>
          <p style={{textAlign: 'center'}}>Redirecting to the app...</p>
        </div>
      </div>
    )
  }
}

export default RedirectToApps;
