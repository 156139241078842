import React, { Component } from 'react';

import {
  apiService,
  envService,
  securityService
} from '../../../services';

import Modal from '../../UI/Modal';
import IntPhoneInput from './Elements/IntPhoneInput';

class TextMeTheAppModal extends Component {

  state = {
    isTMTAFormSubmitted: false,
    isTMTAFormSubmitting: false,
    phoneNumber: '',
    isPhoneNumberValid: false,
    isSMSSent: false
  }

  phoneInputChangeHandler = (iti) => {
    this.setState({
      phoneNumber: `+${iti.selectedCountryData.dialCode}${iti.telInput.value}`,
      isPhoneNumberValid: iti.isValidNumber()
    });
  }

  sendSMSHandler = () => {
    this.setState({isTMTAFormSubmitted: true, isTMTAFormSubmitting: true});
    if (this.state.isPhoneNumberValid) {
      apiService.sendSMS(this.props.homePage.organization.app.id, this.state.phoneNumber, (res) => {
        this.setState({
          phoneNumber: '',
          isSMSSent: true,
          isTMTAFormSubmitted: false,
          isTMTAFormSubmitting: false
        });
      }, (err) => {
        alert('Something went wrong. Please check your phone number and try again.')
        this.setState({
          phoneNumber: '',
          isSMSSent: false,
          isTMTAFormSubmitted: false,
          isTMTAFormSubmitting: false
        });
      })
    } else {
      this.setState({
        phoneNumber: '',
        isTMTAFormSubmitting: false
      });
    }
  }


  // Render
  render () {
    return (
      <Modal
        show={this.props.show}
        close={this.props.close}
        btnTitle={!this.state.isSMSSent && "Send"}
        btnAction={!this.state.isSMSSent && this.sendSMSHandler}
        btnLoading={!this.state.isSMSSent && this.state.isTMTAFormSubmitting}
        btnDisabled={!this.state.isSMSSent && this.state.isTMTAFormSubmitting}>
        <div style={{textAlign: 'center'}}>
          <h2>{this.state.isSMSSent ? 'We just sent a text message describing how to download the app.' : 'Enter your phone number and we\'ll text a link to download the app'}</h2>
          {!this.state.isSMSSent &&
            <IntPhoneInput
            changeHandler={this.phoneInputChangeHandler}
            phone={this.state.phoneNumber}/>
          }
          {this.state.isTMTAFormSubmitted &&
            !this.state.isPhoneNumberValid &&
            <p style={{color: 'red'}}>The entered number is invalid.</p>
          }
          {!this.state.isSMSSent &&
            <p style={{margin: '20px 0', fontSize: '1.1em'}}>Standard text message rates apply</p>
          }
        </div>
      </Modal>
    )
  }
}

export default TextMeTheAppModal;
